@import-normalize;
@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
.app,
#root {
    height: 100%;
    height: 100svh;
    width: 100%;
    min-height: 100%;
}

* {
    font-family: "Montserrat", sans-serif;
}

body {
    margin: 0;
    font-family: "Montserrat", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

input,
textarea,
button,
select,
a,
label,
div {
    -webkit-tap-highlight-color: transparent;
}

@layer utilities {
    .no-scrollbar {
        overflow-style: none !important;
        -ms-overflow-style: none !important;
        /* IE and Edge */
        scrollbar-width: none !important;
        /* Firefox */
    }

    .scrollbar::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px #f4f4f4;
        border-radius: 10px;
        background-color: transparent;
    }

    .scrollbar::-webkit-scrollbar {
        width: 5px;
        background-color: transparent;
    }

    .scrollbar::-webkit-scrollbar-thumb {
        background: transparent;
    }

    .scrollbar:hover::-webkit-scrollbar-thumb {
        -webkit-box-shadow: inset 0 0 6px #dfdfdf;
        background-color: #bdbdbd;
    }

    .no-scrollbar::-webkit-scrollbar {
        width: 0px;
        height: 0px;
        background-color: transparent;
    }

    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }
}

.modal-css .ant-modal-close-icon {
    display: none;
}

.modal-css .ant-modal-content {
    padding: 30px 45px !important;
    overflow: hidden !important;
}

.modal-css .ant-modal-header {
    margin-bottom: 0px;
    display: none;
}

.modal-css .ant-modal {
    margin: auto;
    display: flex;
    align-items: center;
}

.modal-css-center .ant-modal-content {
    padding-left: 47px !important;
    padding-top: 48px !important;
    padding-right: 44px !important;
    padding-bottom: 0 !important;
}

.modal-css-center .ant-modal-close {
    top: 27px !important;
    right: 30px !important;
    width: 24px !important;
    height: 24px !important;
}

.modal-css-center .ant-modal-close:hover {
    background: transparent !important;
}

@media only screen and (max-width: 1024px) {
    .modal-css .ant-modal-close {
        display: none !important;
    }
}

@media only screen and (max-width: 1024px) {
    .modal-css .ant-modal-content {
        padding-left: 0px !important;
        padding-top: 0px !important;
        padding-right: 0px !important;
        padding-bottom: 0px !important;
    }
}

.displayNone #cardNumber {
    display: none;
    width: 100%;
}

.displayUnset #cardNumber {
    display: unset;
    width: 100%;
}

@layer base {
    :root {
        --color-primary: #0078aa;
        --outline-input: #0078aa;
        --color-success: #16c60c;
        --color-link: #2f80ed;
        --color-giftcard-name: #3d4654;
        --color-checkout-title: #3d4654;
        --color-error-light: #eb5757;
        --color-error: #ff4d4f;
        --color-currency: #828282;
        --color-invoice: #242424;
        --color-darkest: #000000;
        --color-dark-light: #31343d;
        --color-dark-lighter: #5a626e;
        --color-dark: #3d4654;
        --color-not-found: #c3e7ea;

        --bg-color-cookies: #152c47;
        --bg-primary: #0078aa;
        --bg-seconday: #409abf;
        --bg-card: #f2f2f2;
        --bg-footer: #e0e0e0;
        --bg-basket-item: #5a626e;
        --bg-invoice-backdrop: #00000073;

        --border-primary: #0078aa;
        --border-seconday: #409abf;
        --border-otp-input: #bdbdbd;
        --border-card: #f2f2f2;
        --border-light: #e0e0e0;
        --border-dark-lighter: #5a626e;
    }

    .theme-jackpot {
        --color-primary: #0078aa;
        --outline-input: #0078aa;
        --color-success: #16c60c;
        --color-link: #2f80ed;
        --color-giftcard-name: #3d4654;
        --color-checkout-title: #3d4654;
        --color-error-light: #eb5757;
        --color-error: #ff4d4f;
        --color-currency: #828282;
        --color-invoice: #242424;
        --color-darkest: #000000;
        --color-dark-light: #31343d;
        --color-dark-lighter: #5a626e;
        --color-dark: #3d4654;
        --color-not-found: #c3e7ea;

        --bg-color-cookies: #152c47;
        --bg-primary: #0078aa;
        --bg-seconday: #409abf;
        --bg-card: #f2f2f2;
        --bg-footer: #e0e0e0;
        --bg-basket-item: #5a626e;
        --bg-invoice-backdrop: #00000073;

        --border-primary: #0078aa;
        --border-seconday: #409abf;
        --border-otp-input: #bdbdbd;
        --border-card: #f2f2f2;
        --border-light: #e0e0e0;
        --border-dark-lighter: #5a626e;
    }
}
